function UpdateLogTypes(activeTypes:string)
{
	const enabledTypes = activeTypes.split(", ");
	const menuElement = $(".menu_renderMenuUserLog");
	const menuItems = $(" li.submenu a.item", menuElement);

	StripLogMenu(menuElement);

	menuItems.each(
		function()
		{
			const menuitemText = $(this).text();
			const isActive = $.inArray(menuitemText, enabledTypes) !== -1;

			if (isActive)
			{
				$(this).parent().addClass("active");
			}
			else
			{
				$(this).parent().removeClass("active");
			}
		}
	);
}

function StripLogMenu(menuElement: JQuery)
{
	const menu = menuElement.find("ul.jd_menu");

	$("> li", menu)
		.unbind("mouseenter.jdmenu mouseleave.jdmenu")
		.unbind("click.jdmenu")
		.find("> a")
			.unbind("focus.jdmenu blur.jdmenu")
			.filter(".accessible")
				.unbind("click.jdmenu");
}